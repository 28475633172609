import _domToReact from "./lib/dom-to-react";
import _htmlDomParser from "html-dom-parser";
var exports = {};
var domToReact = _domToReact;
var htmlToDOM = _htmlDomParser; // decode HTML entities by default for `htmlparser2`

var domParserOptions = {
  decodeEntities: true,
  lowerCaseAttributeNames: false
};
/**
 * Converts HTML string to React elements.
 *
 * @param  {String}   html                    - HTML string.
 * @param  {Object}   [options]               - Parser options.
 * @param  {Object}   [options.htmlparser2]   - htmlparser2 options.
 * @param  {Object}   [options.library]       - Library for React, Preact, etc.
 * @param  {Function} [options.replace]       - Replace method.
 * @return {JSX.Element|JSX.Element[]|String} - React element(s), empty array, or string.
 */

function HTMLReactParser(html, options) {
  if (typeof html !== "string") {
    throw new TypeError("First argument must be a string");
  }

  if (html === "") {
    return [];
  }

  options = options || {};
  return domToReact(htmlToDOM(html, options.htmlparser2 || domParserOptions), options);
}

HTMLReactParser.domToReact = domToReact;
HTMLReactParser.htmlToDOM = htmlToDOM; // support CommonJS and ES Modules

exports = HTMLReactParser;
exports.default = HTMLReactParser;
export default exports;